import { SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  DatePicker,
  Space,
  Typography,
  Upload,
  message
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import dayjs from 'dayjs';
import React, {
  useEffect, useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import Input from 'common/components/Input';
import { getSystemGeneralService, updateSystemGeneralService } from 'common/services/systems';
import { UpdateSystemGeneralParams } from 'common/services/systems/types';
import { getImageURL } from 'common/utils/functions';

const { RangePicker } = DatePicker;
type SystemGeneralForm = {
  gaIds: string;
  gtmIds: string;
  gMapId: string;
  fileFavicon?: File;
  eventDuration?: string[];
  eventEndTime?: string;
  luckyDrawFirstDuration: number;
  luckyDrawSpecialDuration: number;
  luckyDrawFirstBackupDuration: number;
  luckyDrawSpecialBackupDuration: number;
};

const GeneralSM: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  /* React-hooks-form */
  const method = useForm<SystemGeneralForm>({
    mode: 'onSubmit',
    defaultValues: {
      gaIds: '',
      gtmIds: '',
      gMapId: '',
      eventEndTime: undefined,
      fileFavicon: undefined,
      luckyDrawFirstDuration: 0,
      luckyDrawSpecialDuration: 0,
      luckyDrawFirstBackupDuration: 0,
      luckyDrawSpecialBackupDuration: 0,
    },
  });

  /* Queries */
  const {
    data: generalData,
  } = useQuery(
    ['systemsManagement-general', 'list'],
    () => getSystemGeneralService(),
  );

  const { mutate: editGeneralMutate, isLoading: isEditLoading } = useMutation(
    ['systemsManagement-general', 'edit'],
    async (params: UpdateSystemGeneralParams) => updateSystemGeneralService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }

    }
  );

  /* Functions */
  const handleSubmit = (data: SystemGeneralForm) => {
    const {
      luckyDrawFirstDuration,
      luckyDrawSpecialDuration,
      luckyDrawFirstBackupDuration,
      luckyDrawSpecialBackupDuration
    } = data;
    editGeneralMutate({
      ...data,
      eventFrom: data.eventDuration?.[0],
      eventTo: data.eventDuration?.[1],
      eventEndTime: data.eventEndTime,
      luckyDrawFirstDuration: luckyDrawFirstDuration.toString(),
      luckyDrawSpecialDuration: luckyDrawSpecialDuration.toString(),
      luckyDrawFirstBackupDuration: luckyDrawFirstBackupDuration.toString(),
      luckyDrawSpecialBackupDuration: luckyDrawSpecialBackupDuration.toString(),
    });
  };

  /* Effects */
  useEffect(() => {
    if (generalData) {
      method.reset({
        gaIds: generalData.gaIds?.toString(),
        gtmIds: generalData.gtmIds?.toString(),
        gMapId: generalData.gMapId,
        eventEndTime: generalData.eventEndTime,
        eventDuration: [generalData.eventFrom, generalData.eventTo],
        luckyDrawFirstDuration: generalData.luckyDrawFirstDuration || 0,
        luckyDrawSpecialDuration: generalData.luckyDrawSpecialDuration || 0,
        luckyDrawFirstBackupDuration: generalData.luckyDrawFirstBackupDuration || 0,
        luckyDrawSpecialBackupDuration: generalData.luckyDrawSpecialBackupDuration || 0,
      });
      setFileList([{
        uid: '-1',
        name: generalData?.favicon?.split('/').slice(-1)[0] || 'favicon.ico',
        url: getImageURL(generalData?.favicon),
        thumbUrl: getImageURL(generalData?.favicon),
      }]);
    }
  }, [generalData, method]);

  return (
    <div className="p-system_general">
      <FormProvider<SystemGeneralForm> {...method}>
        <form noValidate>
          <Card type="inner">
            <Space className="p-system_general_space" direction="vertical" size={16}>
              <Controller
                name="gaIds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <div>
                    <Typography.Text strong>
                      {t('systemManagement.gaId')}
                      {' '}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </div>
                )}
              />

              <Controller
                name="gtmIds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.gtmId')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="gMapId"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.gMapId')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="fileFavicon"
                render={({ field: { onChange } }) => (
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(info) => {
                      onChange(info.fileList?.[0]?.originFileObj);
                      setFileList([...info.fileList]);
                    }}
                    onRemove={() => onChange(undefined)}
                    maxCount={1}
                    fileList={fileList}
                    accept="image/x-icon"
                  >
                    <Button
                      type="default"
                    >
                      {t('systemManagement.uploadFavicon')}
                    </Button>
                  </Upload>
                )}
              />
              <Space direction="horizontal" className="p-system_general_space" size={24}>
                <Controller
                  name="luckyDrawFirstDuration"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Typography.Text strong>
                        {t('systemManagement.luckyDrawFirstDuration')}
                        {' '}
                      </Typography.Text>
                      <Input
                        type="number"
                        ref={ref}
                        onChange={onChange}
                        value={value}
                        error={error?.message}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="luckyDrawFirstBackupDuration"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Typography.Text strong>
                        {t('systemManagement.luckyDrawFirstBackupDuration')}
                        {' '}
                      </Typography.Text>
                      <Input
                        type="number"
                        ref={ref}
                        onChange={onChange}
                        value={value}
                        error={error?.message}
                      />
                    </div>
                  )}
                />
              </Space>
              <Space direction="horizontal" className="p-system_general_space" size={24}>
                <Controller
                  name="luckyDrawSpecialDuration"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Typography.Text strong>
                        {t('systemManagement.luckyDrawSpecialDuration')}
                        {' '}
                      </Typography.Text>
                      <Input
                        type="number"
                        ref={ref}
                        onChange={onChange}
                        value={value}
                        error={error?.message}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="luckyDrawSpecialBackupDuration"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <div>
                      <Typography.Text strong>
                        {t('systemManagement.luckyDrawSpecialBackupDuration')}
                        {' '}
                      </Typography.Text>
                      <Input
                        type="number"
                        ref={ref}
                        onChange={onChange}
                        value={value}
                        error={error?.message}
                      />
                    </div>
                  )}
                />
              </Space>
              <Space align="start" className="p-system_general_space" size={24}>
                <Controller
                  name="eventDuration"
                  control={method.control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Typography.Text strong>
                        {t('systemManagement.eventDuration')}
                      </Typography.Text>
                      <RangePicker
                        className="u-mt-8"
                        allowClear
                        size="large"
                        showTime
                        value={value ? [dayjs(value[0]), dayjs(value[1])] : undefined}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={(values) => values && onChange(values.map(
                          (item) => dayjs(item).toISOString()
                        ))}
                        style={{ width: '100%', display: 'flex' }}
                      />
                    </>
                  )}
                />
                <Controller
                  name="eventEndTime"
                  control={method.control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Typography.Text strong>
                        {t('systemManagement.eventEndTime')}
                      </Typography.Text>
                      <DatePicker
                        size="large"
                        value={value ? dayjs(value) : null}
                        showTime
                        onChange={(quotaDate) => onChange(quotaDate?.toISOString() || '')}
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: '100%' }}
                        allowClear
                        className="u-mt-8"
                      />
                    </>
                  )}
                />
              </Space>
            </Space>
            <div className="p-system_general_submit u-mt-16">
              <Button
                type="primary"
                disabled={!canEdit}
                loading={isEditLoading}
                onClick={method.handleSubmit(handleSubmit)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            </div>
          </Card>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralSM;
